import "./ToDoList.css";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function List({ id, title, link, shortcut }) {
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    // 로컬 스토리지에서 데이터 불러오기
    const storedCompleted = localStorage.getItem(`todo_${id}`);
    if (storedCompleted !== null) {
      setCompleted(JSON.parse(storedCompleted));
    }
  }, [id]);

  const handleToggle = () => {
    // 토글된 상태를 업데이트하고 로컬 스토리지에 저장 또는 제거
    const updatedCompleted = !completed;
    setCompleted(updatedCompleted);
    if (updatedCompleted) {
      localStorage.setItem(`todo_${id}`, JSON.stringify(updatedCompleted));
    } else {
      localStorage.removeItem(`todo_${id}`);
    }
  };

  return (
    <li>
      <input
        type="checkbox"
        name="checkbox"
        id={id}
        checked={completed}
        onChange={handleToggle}
      />
      <label htmlFor={id}></label>
      <p style={{ color: completed ? "#e5e5e5" : "black" }}>{title}</p>
      <a href={link} className="shortcut" target="_blank" rel="noreferrer">
        {shortcut} &#8594;
      </a>
    </li>
  );
}

function ToDoList() {
  const { i18n } = useTranslation();
  const todoData = i18n.getResourceBundle(i18n.language, "todo") || [];
  // console.log(i18n.language);
  useEffect(() => {
    // resetDate를 초기화하는 로직을 추가
    const now = new Date();
    const resetDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0,
      0,
      0
    );
    if (localStorage.getItem("resetDate") == null) {
      localStorage.setItem("resetDate", resetDate.toString());
    }
  }, []); // 처음 한 번만 실행되도록 빈 배열 전달

  return (
    <section className="td_list">
      <h2 className="text">TO DO LIST</h2>
      <ul className="check_list">
        {Object.values(todoData).map((item) => (
          <List key={item.id} {...item} />
        ))}
      </ul>
    </section>
  );
}

export default ToDoList;
