import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Reset } from "styled-reset";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";
import React, { useEffect } from "react";

import "./Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./component/Header/Header.js";
import Footer from "./component/Footer/Footer.js";
import Home from "./pages/Home.js";
import StreamingList from "./pages/StreamingList.js";
import StreamingGuide from "./pages/StreamingGuide.js";
import AutomationGuide from "./pages/AutomationGuide.js";
import DownloadGuide from "./pages/DownloadGuide.js";
import BroadcastGuide from "./pages/BroadcastGuide.js";
import AwardsGuide from "./pages/AwardsGuide.js";
import IDCreateGuide from "./pages/IDCreateGuide.js";
import FanchantGuide from "./pages/FanchantGuide.js";

function App() {
  useEffect(() => {
    const currentPath = window.location.pathname;
    let language = "ko";

    if (currentPath.includes("jp")) {
      language = "jp";
    } else if (currentPath.includes("cn")) {
      language = "cn";
    } else if (currentPath.includes("en")) {
      language = "en";
    }

    i18n.changeLanguage(language);
  }, []);

  return (
    <Router>
      <div>
        <Reset />
        <I18nextProvider i18n={i18n}>
          <Header />
          <Routes>
            {/* 한국어 */}
            <Route path="/" element={<Home />} />
            <Route path="/streaming_list" element={<StreamingList />} />
            <Route path="/streaming_guide" element={<StreamingGuide />} />
            <Route path="/automation_guide" element={<AutomationGuide />} />
            <Route path="/download_guide" element={<DownloadGuide />} />
            <Route path="/broadcast_guide" element={<BroadcastGuide />} />
            <Route path="/awards_guide" element={<AwardsGuide />} />
            <Route path="/idcreate_guide" element={<IDCreateGuide />} />
            <Route path="/fanchant_guide" element={<FanchantGuide />} />
            {/* 다국어  */}
            <Route path="/:lang" element={<Home />} />
            <Route path="/:lang/streaming_list" element={<StreamingList />} />
            <Route path="/:lang/streaming_guide" element={<StreamingGuide />} />
            <Route
              path="/:lang/automation_guide"
              element={<AutomationGuide />}
            />
            <Route path="/:lang/download_guide" element={<DownloadGuide />} />
            <Route path="/:lang/broadcast_guide" element={<BroadcastGuide />} />
            <Route path="/:lang/idcreate_guide" element={<IDCreateGuide />} />
            <Route path="/:lang/fanchant_guide" element={<FanchantGuide />} />
            <Route path="/:lang/awards_guide" element={<AwardsGuide />} />
          </Routes>
          <Footer />
        </I18nextProvider>
      </div>
    </Router>
  );
}

export default App;
