import styles from "./Movie.module.css";

function Movie() {
  return (
    <section className={styles.main_mv}>
      <div className={styles.mediaWrapper}>
        <iframe
          src="https://www.youtube.com/embed/670qxpZMWDA?si=4-D7GhpPCRxjlU6H"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

export default Movie;
