export const OFFICIAL = [
  {
    id: 1,
    logo: "Daum",
    alt: "fan cafe",
    link: "https://cafe.daum.net/plave",
    title: "Fan cafe",
  },
  {
    id: 2,
    logo: "Weverse",
    alt: "Weverse",
    link: "https://weverse.io/plave/feed",
    title: "Weverse",
  },
  {
    id: 3,
    logo: "Youtube",
    alt: "youtube",
    link: "https://www.youtube.com/@plave_official",
    title: "Youtube",
  },
  {
    id: 4,
    logo: "Tiktok",
    alt: "tiktok",
    link: "https://tiktok.com/@plave_official",
    title: "Tiktok",
  },
  {
    id: 5,
    logo: "X",
    alt: "X",
    link: "https://twitter.com/plave_official",
    title: "X",
  },
  {
    id: 6,
    logo: "Instagram",
    alt: "instagram",
    link: "https://instagram.com/plave_official",
    title: "Instagram",
  },
  {
    id: 7,
    logo: "Facebook",
    alt: "facebook",
    link: "https://facebook.com/plave.official",
    title: "Facebook",
  },
  {
    id: 8,
    logo: "NaverTV",
    alt: "naver tv",
    link: "https://tv.naver.com/plave",
    title: "NaverTV",
  },
  {
    id: 9,
    logo: "Bilibili",
    alt: "bilibili",
    link: "https://space.bilibili.com/3493141629896727?spm_id_from=333.337.0.0",
    title: "Bilibili",
  },
  {
    id: 10,
    logo: "Xiao",
    alt: "Xiao",
    link: "https://www.xiaohongshu.com/user/profile/60c45173000000000101cf86",
    title: "Xiaohongshu",
  },
  {
    id: 11,
    logo: "Vlast",
    alt: "Vlast",
    link: "https://vlastshop.com",
    title: "Vlast shop",
  },
];
