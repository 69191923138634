import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        nav: require("./en/nav.json"),
        oneClick: require("./en/oneClick.json"),
        guide: require("./en/guide.json"),
        todo: require("./en/todo.json"),
      },
      ko: {
        nav: require("./ko/nav.json"),
        oneClick: require("./ko/oneClick.json"),
        guide: require("./ko/guide.json"),
        todo: require("./ko/todo.json"),
      },
      jp: {
        nav: require("./jp/nav.json"),
        oneClick: require("./jp/oneClick.json"),
        guide: require("./jp/guide.json"),
        todo: require("./jp/todo.json"),
      },
      cn: {
        nav: require("./cn/nav.json"),
        oneClick: require("./cn/oneClick.json"),
        guide: require("./cn/guide.json"),
        todo: require("./cn/todo.json"),
      },
    },
    fallbackLng: "ko", // 기본 언어
    debug: true,
    interpolation: {
      escapeValue: false, // react already safe from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
