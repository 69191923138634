import { TwitterTimelineEmbed } from "react-twitter-embed";

function Twitter() {
  return (
    <section className="tweet">
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="Plave_Stream"
        options={{ width: "100%", height: "284px" }}
      />
    </section>
  );
}

export default Twitter;
