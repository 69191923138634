import { OFFICIAL } from "../../constants/officialData.js";

function Official() {
  return (
    <section className="official link">
      <h2 className="text">PLAVE OFFICIAL</h2>
      <ul>
        {OFFICIAL.map((item) => (
          <OfficialComponent key={item.id} {...item} />
        ))}
      </ul>
    </section>
  );
}
function OfficialComponent({ logo, alt, link, title }) {
  return (
    <li className="icon">
      <img
        src={`${process.env.PUBLIC_URL}/assets/logo/${logo}.png`}
        alt={alt}
      />
      <a href={link} target="_blank" className="oneclick" rel="noreferrer">
        {title}
      </a>
    </li>
  );
}

export default Official;
