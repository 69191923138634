import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

import i18n from "../../locales/i18n";
import { I18nextProvider, useTranslation } from "react-i18next";

export default function Header() {
  const { t } = useTranslation("nav");
  const [isShow, setMenu] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    let language = "ko";

    if (currentPath.includes("jp")) {
      language = "jp";
    } else if (currentPath.includes("cn")) {
      language = "cn";
    } else if (currentPath.includes("en")) {
      language = "en";
    } else {
      language = "ko";
    }

    i18n.changeLanguage(language);
  }, []);

  function toggleMenu() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setMenu(!isShow);
    }
  }

  let languageUrl = i18n.language === "ko" ? "/" : `/${i18n.language}/`;

  return (
    <I18nextProvider i18n={i18n}>
      <header>
        <div
          className="container"
          style={{
            background: isShow ? "rgba(251,251,251,1)" : "rgba(251,251,251,0.1",
            boxShadow: isShow ? "gray 0px 1px 100px" : "none",
          }}
        >
          <div className="menu">
            <h1>
              <NavLink to={languageUrl}>{t("title")}</NavLink>
            </h1>
            <div
              className={`button ${isShow ? "active" : ""}`}
              onClick={toggleMenu}
            >
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
          </div>
          <nav className={isShow ? "show" : ""}>
            <ul>
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}streaming_list`}>
                  {t("streamingList")}
                </NavLink>
              </li>
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}streaming_guide`}>
                  {t("streamingGuide")}
                </NavLink>
              </li>
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}automation_guide`}>
                  {t("automationGuide")}
                </NavLink>
              </li>
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}download_guide`}>
                  {t("downloadGuide")}
                </NavLink>
              </li>
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}broadcast_guide`}>
                  {t("broadcastGuide")}
                </NavLink>
              </li>
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}awards_guide`}>
                  {t("awardsGuide")}
                </NavLink>
              </li>
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}idcreate_guide`}>
                  {t("IDCreateGuide")}
                </NavLink>
              </li>
              {/* <li onClick={toggleMenu}>
              <NavLink to={`${languageUrl}awards_guide`}>
                {t("awardsGuide")}</NavLink>
              </li> */}
              <li onClick={toggleMenu}>
                <NavLink to={`${languageUrl}fanchant_guide`}>
                  {t("fanchant")}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </I18nextProvider>
  );
}
