import React from "react";
import styles from "./Popup.module.css";
import i18n from "../../locales/i18n";
import { I18nextProvider, useTranslation } from "react-i18next";

function Popup({ targetData, oneClickData, popupState }) {
  const { t } = useTranslation("oneClick");
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <I18nextProvider i18n={i18n}>
      <div className={styles.popup}>
        <div className={styles.popupWrapper}>
          <div className={styles.close} onClick={popupState}></div>
          <div className={styles.desc}>
            <div className={styles.title}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo/${targetData.logo}.png`}
                alt={targetData.name}
              />
              <h2>{t(`ko.${targetData.name}.description`)}</h2>
            </div>
            <div className={styles.notice}>
              <img
                src={`${process.env.PUBLIC_URL}/Icon-Info.png`}
                alt="notice"
              />
              <p>{t(`ko.notice`)}</p>
            </div>
          </div>

          <div className={styles.device}>
            <div className={styles.type}>
              <h4>
                {targetData.name === "바이브" && !isMobile
                  ? t(`ko.${targetData.name}.types.${oneClickData.name}.desc`)
                  : t(`ko.${targetData.name}.types.${oneClickData.name}.name`)}
              </h4>
              {!targetData.name === "바이브" && !isMobile ? (
                <p style={{ color: "red" }}>{t(`ko.${targetData.name}.pc`)}</p>
              ) : (
                <p>{t(`ko.${targetData.name}.notice`)}</p>
              )}
            </div>
            <div className={styles.oneclick}>
              <ul>
                {oneClickData.buttons &&
                  oneClickData.buttons.map((oneClick, index) => (
                    <li key={oneClick.label}>
                      <a href={oneClick.url}>
                        {t(
                          `ko.${targetData.name}.types.${oneClickData.name}.buttons.label`
                        )}
                        {index + 1}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </I18nextProvider>
  );
}

export default Popup;
