import { ONECLICKDATA } from "../../constants/oneClickData";
import { useTranslation } from "react-i18next";

function OneClickGlo() {
  const { t } = useTranslation("oneClick");
  return (
    <section className="streaming_glo link">
      <div className="text">
        <h2>{t("glo.title")}</h2>
        <p className="update">UPDATE 2024.10.20</p>
      </div>
      <ul>
        {ONECLICKDATA.glo.map((service, serviceIdx) => (
          <li key={serviceIdx} className="icon">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo/${service.logo}.png`}
              alt={service.name}
            />
            <a
              href={service.link}
              target="_blank"
              className="oneclick"
              rel="noreferrer"
            >
              {t(`glo.type.${serviceIdx}.name`)}
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default OneClickGlo;
