import { useState } from "react";
import { ONECLICKDATA } from "../../constants/oneClickData";
import Popup from "./Popup"; //
import { useTranslation } from "react-i18next";

function OneClickKo() {
  const { t } = useTranslation("oneClick");
  const [showPopup, setShowPopup] = useState(false);
  const [targetValue, setTargetValue] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [userAgent, setUserAgent] = useState(null);
  const [oneClickData, setOneClickData] = useState(null);

  const userAgentAll = navigator.userAgent.toLowerCase();
  const ShowPopup = (e) => {
    if (/windows/.test(userAgentAll)) setUserAgent("Windows");
    else if (/android/.test(userAgentAll)) setUserAgent("Android");
    else if (/macintosh;/.test(userAgentAll)) setUserAgent("Mac");
    else if (/iphone;/.test(userAgentAll)) setUserAgent("iPhone");
    else if (/ipad/.test(userAgentAll)) setUserAgent("iPad");
    setShowPopup(true);
    const targetValue = e.currentTarget.getAttribute("data-value");
    setTargetValue(targetValue);
    const targetData = ONECLICKDATA.findServiceByName(targetValue);
    const oneClickData = ONECLICKDATA.findServiceByUserAgent(
      targetValue,
      userAgentAll
    );
    setTargetData(targetData);
    setOneClickData(oneClickData);

    console.log("targetValue:", targetValue);
    console.log("userAgent:", userAgent);
    console.log("targetData:", targetData);
    console.log("oneClickData:", oneClickData);
  };
  const hidePopup = () => {
    setShowPopup(false);
  };

  return (
    <section className="streaming_ko link">
      <div className="text">
        <h2>{t("ko.title")}</h2>
        <p className="update">UPDATE 2024.11.16</p>
      </div>
      <ul>
        {ONECLICKDATA.ko.map((service) => (
          <li
            className="icon"
            onClick={ShowPopup}
            data-value={service.name}
            key={service.name}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo/${service.logo}.png`}
              alt={service.name}
            />
            {t(`ko.${service.name}.name`)}
          </li>
        ))}
        {showPopup ? (
          <Popup
            value={targetValue}
            targetData={targetData}
            oneClickData={oneClickData}
            popupState={hidePopup}
          />
        ) : null}
      </ul>
    </section>
  );
}

export default OneClickKo;