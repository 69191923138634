import { I18nextProvider } from "react-i18next";
import i18n from "../locales/i18n";

import Movie from "../component/home/Movie.js";
import ToDoList from "../component/home/ToDoList.js";
import OneClickKo from "../component/home/OneClickKo.js";
import OneClickGlo from "../component/home/OneClickGlo.js";
import Official from "../component/home/Official.js";
import Twitter from "../component/home/Twitter.js";

import Modal from "../component/Modal.js";

export default function Home() {
  return (
    <I18nextProvider i18n={i18n}>
      <main
        className="main container"
        style={{ display: "flex", paddingTop: "104px" }}
      >
        {/* <Modal /> */}
        <Movie />
        <ToDoList />
        <OneClickKo />
        <OneClickGlo />
        <Official />
        <Twitter />
      </main>
    </I18nextProvider>
  );
}
