import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Guide.css";
import "swiper/css";
import i18n from "../locales/i18n";
import { useTranslation } from "react-i18next";

export default function StreamingList() {
  const [showTab, setShowTab] = useState(0);
  const handleTabClick = (index) => {
    setShowTab(parseInt(index, 10));
    // console.log(`${index}, ${showTab}`);
  };

  const { t } = useTranslation("guide");
  const Data = t("awardGuide.list", { returnObjects: true });

  return (
    <main className="main container">
      <h2 className="title">{t("awardGuide.title")}</h2>
      <Swiper spaceBetween={10} slidesPerView={"auto"}>
        {Data.map((item, index) => (
          <SwiperSlide
            key={index}
            onClick={() => handleTabClick(index)}
            className={`${index === showTab ? "active" : ""}`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo/${item.logo}.png`}
              alt="/"
            />
            <div>{item.title}</div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="tab_content">
        {Data.map((item, index) => (
          <div
            key={index}
            style={{ display: index === showTab ? "flex" : "none" }}
          >
            {item.pages && item.pages.length > 0 ? (
              item.pages.map((page, i) => (
                <img
                  src={`${
                    process.env.PUBLIC_URL
                  }/assets/guide/award/awardGuide_${
                    item.type
                  }_${page}_${i18n.language.substr(0, 2)}_${item.release}.webp`}
                  alt={`${item.type}_${i18n.language.substr(0, 2)}_${
                    item.release
                  }_${page}`}
                />
              ))
            ) : (
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/assets/guide/awards_guide/award_${
                  item.type
                }_${i18n.language.substr(0, 2)}_${item.release}.webp`}
                alt={`${item.type}_${i18n.language.substr(0, 2)}_${
                  item.release
                }`}
              />
            )}
          </div>
        ))}
      </div>
    </main>
  );
}
