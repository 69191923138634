import "./Footer.css";
import i18n from "../../locales/i18n";

export default function Footer() {
  const changeLanguage = (language) => {
    const currentPath = window.location.pathname;
    const segments = currentPath.split("/");

    // 한국어 버튼
    if (language === "ko") {
      if (segments.length > 2) {
        const newPath = `/${segments[segments.length - 1]}`;
        window.history.pushState({}, "", newPath);
        i18n.changeLanguage(language);
      } else {
        const newPath = `/`;
        window.history.pushState({}, "", newPath);
        i18n.changeLanguage(language);
      }
    }
    // 타언어 버튼
    else {
      const newPath = `/${language}/${segments[segments.length - 1]}`;
      window.history.pushState({}, "", newPath);
      i18n.changeLanguage(language);
    }

    document
      .querySelectorAll(".language button")
      .forEach((btn) => btn.classList.remove("active"));
    document.querySelector(`.${language}`).classList.add("active");
  };

  return (
    <footer className="container">
      <div className="language">
        <button
          className={`ko ${i18n.language === "ko" ? "active" : ""}`}
          onClick={() => changeLanguage("ko")}
        >
          한국어
        </button>
        <p className="slash">│</p>
        <button
          className={`en ${i18n.language === "en" ? "active" : ""}`}
          onClick={() => changeLanguage("en")}
        >
          English
        </button>
        <p className="slash">│</p>
        <button
          className={`cn ${i18n.language === "cn" ? "active" : ""}`}
          onClick={() => changeLanguage("cn")}
        >
          中文
        </button>
        <p className="slash">│</p>
        <button
          className={`jp ${i18n.language === "jp" ? "active" : ""}`}
          onClick={() => changeLanguage("jp")}
        >
          日本語
        </button>
      </div>
      <div className="info">
        <div className="contact">
          <p>E-mail : plavestream@gmail.com</p>
          <p className="slash">│</p>
          <p>Twitter : @Plave_Stream</p>
        </div>
        <div className="copyright">
          <p>Copyright © 2024 Team PLAVE Stream All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
