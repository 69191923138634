export const ONECLICKDATA = {
  ko: [
    {
      name: "멜론",
      logo: "Melon",
      description: "멜론 원클릭 스트리밍",
      notice: "4개의 버튼을 모두 클릭해주세요!",
      type: [
        {
          name: "모바일",
          class: ["Android", "iPhone", "iPad"],
          buttons: [
            { label: "원클릭 1", url: "https://tinyurl.com/mv748xsv" },
            { label: "원클릭 2", url: "https://tinyurl.com/29z92t98" },
            { label: "원클릭 3", url: "https://tinyurl.com/3h88nrsz" },
            { label: "원클릭 4", url: "https://tinyurl.com/fjrb3as3" },
          ],
        },
        {
          name: "PC (Windows)",
          class: ["Windows"],
          buttons: [
            { label: "원클릭 1", url: "https://tinyurl.com/mrkebh7j" },
            { label: "원클릭 2", url: "https://tinyurl.com/29arca5n" },
            { label: "원클릭 3", url: "https://tinyurl.com/3mn8uwh2" },
            { label: "원클릭 4", url: "https://tinyurl.com/3ryfdw7y" },
          ],
        },
        {
          name: "PC (Mac)",
          class: ["Mac"],
          buttons: [
            { label: "원클릭 1", url: "https://tinyurl.com/236y4r9h" },
            { label: "원클릭 2", url: "https://tinyurl.com/yfuw8w84" },
            { label: "원클릭 3", url: "https://tinyurl.com/u235tb46" },
            { label: "원클릭 4", url: "https://tinyurl.com/yycr4jyc" },
          ],
        },
      ],
    },
    {
      name: "지니",
      logo: "Genie",
      description: "지니 원클릭 스트리밍",
      type: [
        {
          name: "안드로이드",
          class: ["Android"],
          buttons: [{ label: "원클릭", url: "https://tinyurl.com/2rd47kkp" }],
        },
        {
          name: "IOS",
          class: ["iPhone", "iPad"],
          buttons: [{ label: "원클릭", url: "https://tinyurl.com/5n7896d7" }],
        },
        {
          name: "PC",
          class: ["Windows", "Mac"],
          buttons: [{ label: "원클릭", url: "https://tinyurl.com/mstevy3z" }],
        },
      ],
    },
    {
      name: "벅스",
      logo: "Bugs",
      description: "벅스 원클릭 스트리밍",
      notice: "아래의 버튼 중 하나만 클릭해주세요!",
      type: [
        {
          name: "Mobile",
          class: ["Android", "iPhone", "iPad"],
          buttons: [{ label: "원클릭", url: "https://han.gl/hVABV" }],
        },
        {
          name: "PC",
          class: ["Windows", "Mac"],
          buttons: [{ label: "원클릭", url: "https://han.gl/COdK2" }],
        },
      ],
    },
    {
      name: "플로",
      logo: "Flo",
      description: "플로 원클릭 스트리밍",
      type: [
        {
          name: "공통",
          class: ["Android", "iPhone", "iPad", "Windows", "Mac"],
          buttons: [{ label: "원클릭", url: "https://han.gl/Y2bYT" }],
        },
      ],
    },
    {
      name: "바이브",
      logo: "Vibe",
      description: "바이브 원클릭 스트리밍",
      notice: "4개의 버튼을 모두 클릭해주세요!",
      type: [
        {
          name: "모바일",
          class: ["Android", "iPhone", "iPad"],
          buttons: [
            { label: "원클릭 1", url: "https://tinyurl.com/3az388nx" },
            { label: "원클릭 2", url: "https://tinyurl.com/yf9xtryv" },
            { label: "원클릭 3", url: "https://tinyurl.com/32zh6wd2" },
            { label: "원클릭 4", url: "https://tinyurl.com/yc8nmnax" },
          ],
        },
        {
          name: "PC",
          desc: "바이브 원클릭 스밍리스트는 모바일 환경에서만 사용가능합니다. 모바일 환경에서 접속해주세요.",
          class: ["Windows", "Mac"],
        },
      ],
    },
  ],
  glo: [
    {
      name: "스포티파이 바로가기",
      logo: "Spotify",
      link: "https://open.spotify.com/playlist/5zYGY3brj9UzzjRFFrKphd?si=OYyyBV1mQ267Nr5b7u-nvw",
    },
    {
      name: "애플뮤직 바로가기",
      logo: "AppleMusic",
      link: "https://music.apple.com/kr/playlist/plave-playlist-연말-시상식-권장/pl.u-AkAmPl3cl2dq6vX",
    },
    {
      name: "유튜브뮤직 바로가기",
      logo: "YoutubeMusic",
      link: "https://music.youtube.com/playlist?list=PLgqeSLUp6DDnXMjUjq7qsTWmnsUvl2siL&si=R2H-Aqw6qjrkyjMd",
    },
  ],
  findServiceByName: (name) => {
    return ONECLICKDATA.ko.find((service) => service.name === name);
  },
  findServiceByUserAgent: (name, userAgent) => {
    const service = ONECLICKDATA.ko.find((service) => service.name === name);

    if (service) {
      const matchingType = service.type.find(
        (type) =>
          type.class &&
          type.class.some((device) => userAgent.includes(device.toLowerCase()))
      );

      if (matchingType) {
        return matchingType || null;
      }
    }

    return null;
  },
};
